<template>
  <CContainer class="d-flex content-center min-vh-100">
    <CRow>
      <CCol>
        <CCardGroup>
          <CCard class="p-4">
            <CCardBody>
              <CImg src="images/howcost-office.png" width="360px" class="m-3"/>
              <!-- <p><strong>XCost 제품 관리 시스템 로그인</strong></p> -->
              <p class="mb-4" style="font-size: 11pt;"><strong>(주)하우코스트 관리자 시스템</strong></p>
              <CForm @submit="onSubmit">
                <CInput
                        v-model="userId"
                        placeholder="Username"
                        autocomplete="off"
                        required
                >
                  <template #prepend-content><CIcon name="cil-user"/></template>
                </CInput>
                <CInput
                        v-model="userPwd"
                        placeholder="Password"
                        type="password"
                        autocomplete="off"
                        aria-required="true"
                        requied
                >
                  <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                </CInput>
                <div>
                  <CButton type="submit" color="howcost" style="border-radius: 3px; font-size: 12pt;" class="mt-1 px-4" :disabled="isProcessing">
                    <CSpinner size="sm" type="grow" v-if="isProcessing"></CSpinner>
                    로그인
                  </CButton>
                </div>
              </CForm>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import {alertError, alertWarn} from '../common/utils';


  export default {
  name: 'Login',
  data(){
    return {
      loginMessage: 'LOGIN 하세요',
      userId: null,
      userPwd: null,
      isProcessing: false,
      // userId: "test-admin"  ,
      // userPwd: "test-admin-password",

    }
  },
  async created(){
    // console.log("####### Login.created() reset baseURL to server URL : " + $baseURL);

    if( this.$store.state.adminStore.isAuth ){
        this.redirect();
    }

    if( localStorage.user ){
      //console.log( "######## Login.created() ------------ localStorage.user: ", localStorage.user );
      try{
        const rs = await this.$store.dispatch('setLoginState', JSON.parse( localStorage.user ));
        if(rs) this.redirect();
      }catch(err){
        console.log( "[CREATED] -------------- err: ", err.message );
      }
    }

  },
  beforeMount(){},
  mounted() {},
  methods: {
    async onSubmit(evt){
      evt.preventDefault();
      console.log("------------------> Login.onSubmit()..............")

      try{
        this.isProcessing = true;
        const rs = await this.$store.dispatch('LOGIN', {userId:this.userId, userPwd:this.userPwd });
        console.log( "---- Login.onSubmit ...................rs: ", rs);
        console.log( "---- Login.onSubmit ...................state: ", this.$store.state.adminStore);
        console.log( "---- Login.onSubmit ...................isAuth: ", this.$store.state.adminStore.isAuth);
        console.log( "---- Login.onSubmit ...................$isAuthed: ", $isAuthed);
        if(!this.$store.state.adminStore.isAuth){
          await alertWarn(this.$bvModal, "아이디 또는 비밀번호가 일치하지 않습니다.", "로그인 실패");
        }else{
          this.redirect();
        }
      }catch(err){
        console.log( err );
        await alertError( this.$bvModal, err.message, "9999" );
      }finally{
        this.isProcessing = false;
      }


      // console.log("#########################===============> ", res);
    },
    redirect() {

      console.log("redirect() ===============> isAuth: ", this.$store.state.adminStore.isAuth);

      //if( !this.$store.state.adminStore.isAuth ) return;

      let routePath = '/admin-x';

      console.log("####### Login.redirect ----> " + routePath );

      this.$router.push({ path: routePath  }, () => { }, () => { });

      // alertModal( this.$bvModal, {title: '로그인 성공', text:'환영합니다.', level: 'info'}, (ok)=>{});

    },


  }
}
</script>
